import { lazy, Suspense, useState } from 'react'
import Cookies from 'js-cookie'
import CookiesNotice from './components/CookiesNotice'
import { Spinner } from '../components/Spinner'

const CookiesModal = lazy(() => import('./components/CookiesDisclaimer'))

export function CookiesSettings() {
  const [showCookiePolicy, setShowCookiePolicy] = useState(
    !Cookies.get('accept_cookie_policy_2')
  )

  const [showCookieNotice, setShowCookieNotice] = useState(true)
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)

  const handleOpenDisclaimerState = () => {
    setShowCookieNotice(false)
    setShowDisclaimerModal(true)

    App.GAEvent('Cookie Notification', 'Click Preferences')
  }

  const handleCancelDisclaimer = () => {
    setShowDisclaimerModal(false)
    setShowCookieNotice(true)
  }

  const handleAgree = () => {
    Cookies.set('accept_cookie_policy_2', 1, {
      expires: 365,
      domain: App.State.cookieDomain,
    })
    Cookies.set('accept_ad_cookies', 1, {
      expires: 365,
      domain:
        App.State.appEnvironment === 'development'
          ? 'localhost'
          : App.State.cookieDomain,
    })
    Cookies.set('accept_analytics_cookies', 1, {
      expires: 365,
      domain:
        App.State.appEnvironment === 'development'
          ? 'localhost'
          : App.State.cookieDomain,
    })

    setShowCookiePolicy(false)

    App.GAEvent('Cookie Notification', 'Click Agree')
  }

  if (!showCookiePolicy) return null

  return [
    <CookiesNotice
      clickAgree={handleAgree}
      openDisclaimer={handleOpenDisclaimerState}
      open={showCookieNotice}
    />,
    <Suspense fallback={<Spinner />}>
      <CookiesModal
        modalIsOpen={showDisclaimerModal}
        openCookiesModal={handleOpenDisclaimerState}
        handleCancelDisclaimer={handleCancelDisclaimer}
        closeCookiesModal={() => setShowDisclaimerModal(false)}
      />
    </Suspense>
  ]
}