const CookiesNotice = ({ open, clickAgree, openDisclaimer }) =>
  open && (
    <div
      id="cookies-notice"
      className="fixed-bottom cookies-footer container shadow-lg p-3"
    >
      <div className="float-start col-xxl-9">
        <p className="cookies-text mb-2">
          {I18n.t('cookies.notice_html.0')}{' '}
          <a href="/privacy">{I18n.t('cookies.notice_html.1')}</a>.
        </p>
      </div>
      <div className="float-end">
        <button
          className="btn btn-primary cookie-button me-3 rounded-8"
          type="button"
          id="agree"
          name="agree"
          onClick={clickAgree}
        >
          <i className="fas fa-check fa-fw" />
          {I18n.t('agree')}
        </button>
        <button
          className="btn btn-secondary cookie-button rounded-8"
          type="button"
          id="cookie-preferences"
          name="cookie-preferences"
          onClick={openDisclaimer}
        >
          {I18n.t('preferences')}
        </button>
      </div>
    </div>
  )

export default CookiesNotice
